.ctaContainer {
  max-width: 1500px;
  background-color: #fff;
  padding: 29px 42px;
  border-radius: 16px;
}

.ctaHeaderContent {
  text-align: left;
}

.ctaHeaderContent div:nth-child(1) {
  color: #424243;
  font-family: Roboto;
  font-size: 34px;
  font-style: normal;
  font-weight: 700;
  line-height: 48px;
}

.ctaHeaderContent div:nth-child(2) {
  color: #424243;
  font-family: Roboto;
  font-size: 20px;
  font-style: normal;
  font-weight: 400;
  line-height: 32px;
}

.ctaHeaderButton {
  display: flex;
  justify-content: center;
  align-items: center;
}

@media screen and (max-width: 992px) {
  .ctaHeaderContent div:nth-child(1) {
    font-size: 1.4rem;
    line-height: 1.6rem;
    text-align: center;
  }

  .ctaHeaderContent div:nth-child(2) {
    font-size: 1rem;
    line-height: 1.6rem;
    text-align: center;
  }

  .ctaHeaderButton {
    margin-top: 1rem;
  }
}