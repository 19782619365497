.stepsWrapper {
  width: 100%;
  max-width: 100%;
  padding: 60px 3%;
  background-color: #FFF;
}

.stepsContainer {
  max-width: 1500px;
}

.headerColumn {
  color: var(--ResMed-Black, var(--Text-Body, #424243));
  text-align: center;
  font-family: Roboto;
  font-size: 41px;
  font-style: normal;
  font-weight: 400;
  line-height: 46px;
  margin-bottom: 48px;
}

.video {
  margin-top: 6rem;
  width: 100%;
  object-fit: contain;
}