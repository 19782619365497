.dictionariesWrapper {
  width: 100%;
  max-width: 100%;
  padding: 60px 3%;
  background-color: #F5FBFF;
  color: #424243;
}

/* Dictionary */

.dictionaryContainer {
  max-width: 1500px;
}

.dictionaryHeaderCol {
  color: var(--ResMed-Black, var(--Text-Body, #424243));
  text-align: center;
  font-family: Roboto;
  font-size: 41px;
  font-style: normal;
  font-weight: 400;
  line-height: 46px;
  margin-top: 70px;
  margin-bottom: 70px;
}

.dictionaryBodyRow {
  justify-content: center;
}

.dictionaryBodyCol {
  margin-bottom: 20px;
  min-width: 200px;
  height: auto;
}

@media screen and (max-width: 992px) {
  .dictionaryBodyCol {
    width: 500px;
  }
}