/* Button Row */

.buttonColumn {
  text-align: left;
  margin-top: 20px;
}

.buttonStarted {
  max-width: 342px;
  border-radius: 41px;
  background-color: #B72755;
  border: 4px solid #B72755;
  font-size: 22px;
}

.buttonStarted:hover {
  border: 4px solid #B72755;
  background-color: #fff;
  color: #B72755;
}

.buttonStarted>span {
  position: relative;
  top: 4px;
  margin-left: 5px;
}

a {
  color: #424243;
  font-weight: 700;
}

@media screen and (max-width: 992px) {
  .buttonColumn {
    text-align: center;
  }

  .buttonStarted {
    font-size: 1rem;
    line-height: 20px;
  }

  .buttonStarted>span {
    font-size: 1rem;
  }
}