.signUpWrapper {
  width: 100%;
  max-width: 100%;
  padding: 0 3%;
  background-color: #F5FBFF;
}

.signUpContainer {
  max-width: 1500px;
  padding: 64px 0;
  border-radius: 45px;
}

.signUpRow {
  background-color: #fff;
  border-radius: 45px;
  max-height: 100%;
  box-shadow: 0px 4.665px 18.661px 0px rgba(0, 0, 0, 0.10);
}

.signUpColumnLeft {
  padding: 0;
}

.signUpImage {
  border-top-left-radius: 45px;
  border-bottom-left-radius: 45px;
  object-fit: cover;
  height: 100%;
}

.signUpColumnRight {
  padding: 40px;
  text-align: left;
}

.formSignUp {
  color: #424243 !important;
  font-family: Roboto !important;
}

.signUpHeader {
  color: #424243;
  font-family: Roboto;
  font-size: 50px;
  font-style: normal;
  font-weight: 400;
  line-height: 54px;
  margin-bottom: 15px;
}

.signUpSubHeader {
  color: #424243;
  font-family: Roboto;
  font-size: 23.326px;
  font-style: normal;
  font-weight: 400;
  line-height: 27.991px;
  margin-bottom: 30px;
}

@media screen and (max-width: 992px) {
  .signUpWrapper {
    padding: 0
  }

  .signUpContainer {
    border-radius: 0;
    padding-top: 0;
  }

  .signUpRow {
    border-radius: 0;
  }

  .signUpImage {
    border-top-left-radius: 0 !important;
    border-bottom-left-radius: 0 !important;
    width: 100%;
  }

  .signUpHeader {
    font-size: 2rem;
    line-height: 2.5rem;
  }

  .signUpSubHeader {
    font-size: 1.4rem;
    line-height: 1.6rem;
  }

  .signUpRow {
    width: 100%;
    margin: 0;
  }
}