.modal {
  padding: 10px;
}

.modal-dialog {
  max-width: 1000px;
  min-width: 250px;
  width: auto;
}

.modal-header {
  border-bottom: 0;
}