.modalHeader {
  justify-content: right;
}

.modalHeader>span {
  cursor: pointer;
}

.modalContainer {
  margin-bottom: 50px;
}

.modalColumnLeft {
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
}

.signUpImage {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0 30px 30px 30px;
}

.modalColumnRight>h1 {
  color: #424243;
  font-family: Roboto;
  font-size: 41px;
  font-weight: 400;
  line-height: 46px;
  letter-spacing: 0em;
  text-align: left;
  margin-bottom: 30px;
}

.modalColumnRight>p {
  color: #424243;
  font-family: Roboto;
  font-size: 20px;
  font-weight: 400;
  line-height: 32px;
  letter-spacing: 0em;
  text-align: left;
  margin-bottom: 40px;
}

@media screen and (max-width: 992px) {
  .signUpImage {
    padding: 0 1rem 2rem 1rem;
  }

  .modalColumnRight>h1 {
    font-size: 2.5rem;
    line-height: 3rem;
    text-align: center;
  }

  .modalColumnRight>p {
    font-size: 1rem;
    line-height: 1.6rem;
    margin-bottom: 2rem;
  }
}