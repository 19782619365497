.headerWrapper {
  width: 100%;
  max-width: 100%;
  padding: 0 0 0 3%;
  background-color: #0367AA;
  background: linear-gradient(rgb(0, 109, 183) 0%, rgb(0, 73, 122) 114.77%);
}

.headerContainer {
  max-width: 1540px;
  padding-bottom: 4rem;
}

/* Logo Row */

.logoColumn {
  padding: 2% 0 2% 1%;
  text-align: left;
}

.logoImage {
  max-height: 88px;
  text-align: right;
}

@media screen and (max-width: 992px) {
  .headerWrapper {
    padding: 0 3%;
  }
}

/* Content Row */

.contentColumnLeft {
  padding-top: 50px;
  padding-bottom: 20px;
}

.contentTitle {
  color: #FFF;
  font-family: Roboto;
  font-size: 80px;
  font-weight: 300;
  line-height: 100px;
  text-align: left;
}

.contentText {
  color: #FFF;
  font-family: Roboto;
  font-size: 20px;
  font-weight: 400;
  line-height: 32px;
  text-align: left;
}

.contentSubtext {
  color: #FFF;
  font-family: Roboto;
  font-size: 14px;
  font-weight: 400;
  line-height: 24px;
  text-align: left;
  margin-bottom: 10px;
}

.contentColumnRight {
  display: flex;
  align-items: center;
  justify-content: center;
}


@media (min-width: 1400px) {
  .contentImage {
    max-width: 600px;
  }
}

@media screen and (max-width: 992px) {
  .contentTitle {
    font-size: 2.5rem;
    line-height: 3rem;
    text-align: center;
  }

  .contentText {
    font-size: 1rem;
    line-height: 1.6rem;
    text-align: center;
  }

  .contentSubtext {
    font-size: 0.8rem;
    line-height: 1.2rem;
    text-align: center;
  }

  .contentColumnRight {
    max-width: 450px;
    margin: 0 auto;
  }

  .contentImage {
    max-width: 100%;
    padding: 30px;
  }
}