.policyLink {
  color: #424243;
  margin-left: 4px;
  font-weight: 700;
}

.buttonSubmit {
  margin-top: 30px;
  max-width: 342px;
  border-radius: 41px;
  background-color: #B72755;
  border: 4px solid #B72755;
  font-size: 22px;
  padding-top: 2px;
}

.buttonSubmit:hover {
  border: 4px solid #B72755;
  background-color: #fff;
  color: #B72755;
}

.buttonSubmitText>span {
  position: relative;
  top: 4px;
  margin-left: 5px;
}

.signUpIcon {
  cursor: pointer;
  padding-left: 5px;
  padding-right: 5px;
}

.radioButton {
  background-color: #fff;
  color: #000000;
  min-width: 100px;
  height: 56px;
  border-radius: 7px;
  border: 2px solid #fff;
  margin-right: 0.8rem;
  margin-bottom: 0.8rem;
  font-size: 15px;
  font-weight: 600;
  box-shadow: 0px 3px 1px 0px #0000000A;
  box-shadow: 0px 3px 8px 0px #0000001F;
}

.radioButton:hover {
  background-color: #eee;
  border: 2px solid #eee;
  color: #000000;
}

.radioButton:active {
  background-color: #eee !important;
  border: 2px solid #eee !important;
  color: #000000 !important;
}

.radioButtonSelected {
  border: 2px solid #6D6D6D;
}

.radioButtonSelected:hover {
  border: 2px solid #6D6D6D;
}

.radioButtonError {
  color: rgb(220, 53, 69);
  border: 2px solid rgb(220, 53, 69);
}

.radioButtonError:hover {
  color: rgb(220, 53, 69);
  background-color: #eee;
  border: 2px solid rgb(220, 53, 69);
}

.radioButtonSuccess {
  color: rgb(25, 135, 84);
}

.radioButtonSuccess:hover {
  background-color: #eee;
  border: 2px solid #eee;
  color: rgb(25, 135, 84);
}

.radioButtonSelectedSuccess {
  border: 2px solid rgb(25, 135, 84);
}

.radioButtonSelectedSuccess:hover {
  background-color: #eee;
  border: 2px solid rgb(25, 135, 84);
  color: rgb(25, 135, 84);
}

.checkform {
  margin-top: 15px;
}

.expandIcon {
  cursor: pointer;
  margin-top: 11px;
  margin-left: 10px;
}

.expandedText {
  margin-top: 10px;
  padding-left: 24px;
  font-size: 13px;
}

@media screen and (max-width: 768px) {
  .buttonSubmitText {
    font-size: 1rem;
    line-height: 20px;
  }

  .buttonSubmitText>span {
    font-size: 1rem;
    top: 1.5px;
  }
}