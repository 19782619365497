.questionsWrapper {
  width: 100%;
  max-width: 100%;
  padding: 60px 3% 20px 3%;
  background-color: #F6F6F6;
}

.questionsContainer {
  max-width: 1500px;
}

.questionsImage {
  max-width: 226px;
}

.questionsStack {
  text-align: left;
}

.questionsStack div:nth-child(2) {
  color: #424243;
  font-family: Roboto;
  font-size: 50px;
  font-style: normal;
  font-weight: 400;
  line-height: 54px;
}

.questionsStack div:nth-child(3) {
  color: #000;
  font-family: Roboto;
  font-size: 20px;
  font-style: normal;
  font-weight: 400;
  line-height: 32px;
}

.questionsStack div:nth-child(4) {
  color: #B72755;
  font-family: Roboto;
  font-size: 24px;
  font-style: normal;
  font-weight: 700;
  line-height: 32px;
  text-decoration-line: underline;
  cursor: pointer;
}

@media screen and (max-width: 992px) {
  .questionsStack {
    text-align: center;
    margin-bottom: 3rem;
  }

  .questionsImage {
    margin: auto;
  }

  .questionsStack div:nth-child(2) {
    font-size: 2rem;
    line-height: 3rem;
  }
  
  .questionsStack div:nth-child(3) {
    font-size: 1rem;
    line-height: 1.6rem;
  }
  
  .questionsStack div:nth-child(4) {
    font-size: 1.4rem;
    line-height: 1.6rem;
  }
}