.stepContainer {
  text-align: left;
}

.stepContainer:hover {
  cursor: pointer;
}

.stepContainer:hover .stepNumber>div {
  color: #0077C0;
  border-bottom: 10px solid #0077C0;
}

.stepContainer:hover .stepSymbol > svg > circle {
  stroke: #0077C0;
  fill: #0077C0;
}

.stepContainer .stepSymbol > svg > circle {
  stroke: #66ADD9;
  fill: #fff;
  transition: all 0.3s ease;
}

.stepNumber>div {
  display: inline-block;
  color: #66ADD9;
  font-family: Roboto;
  font-size: 68px;
  font-style: normal;
  font-weight: 700;
  line-height: 72px;
  padding: 28px 0;
  margin-bottom: 24px;
  border-bottom: 10px solid #66ADD9;
  transition: color 0.3s ease;
}

.stepHeader>div {
  display: inline-block;
  max-width: 50%;
  color: var(--ResMed-Black, var(--Text-Body, #424243));
  font-family: Roboto;
  font-size: 24px;
  font-style: normal;
  font-weight: 700;
  line-height: 36px;
  margin-bottom: 24px;
  min-height: 72px;
}

.stepBody {
  color: var(--Text-Body, #424243);
  font-family: Roboto;
  font-size: 20px;
  font-style: normal;
  font-weight: 400;
  line-height: 32px;
}

@media screen and (max-width: 992px) {
  .stepContainer {
    margin-bottom: 4rem;
  }

  .stepNumber>div {
    font-size: 2rem;
    line-height: 3rem;
    padding: 0.5rem 0;
    margin-bottom: 1rem;
  }

  .stepHeader>div {
    display: block;
    font-size: 1.4rem;
    line-height: 1.6rem;
    margin-bottom: 0.4rem;
    max-width: 100%;
    min-height: 40px;
  }

  .stepBody {
    font-size: 1rem;
    line-height: 1.6rem;
  }
}