.articleWrapper {
  width: 100%;
  margin: 0 auto;
}

.headerContainer {
  padding-bottom: 20px;
  border-bottom: 0.5px solid #9B9B9E;
}

.headerImage {
  margin: 40px 0;
  max-height: 70px;
}

.headerTitle {
  text-align: left;
  font-size: 26px;
  font-weight: 700;
  line-height: 36px;
}

.bodyContainer {
  padding-top: 40px;
  font-size: 20px;
  font-weight: 400;
  line-height: 32px;
  text-align: left;
}

.bodyContainer a {
  color: inherit;
}