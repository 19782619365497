.card {
  background-color: #fff;
  margin-bottom: 40px;
  text-align: left;
  border: 0;
  border-radius: 16px;
}

.card-header {
  vertical-align: middle;
  background-color: #fff;
  border-bottom: 0;
  padding: 16px;
}

.cardHeaderText {
  color: #000;
  font-family: Roboto;
  font-size: 24px;
  font-style: normal;
  font-weight: 700;
  line-height: 36px;
  margin-left: 21px;
}

.cardHeaderFullBorder {
  border-radius: 16px !important;
  cursor: pointer;
  display: flex;
  align-items: center;
}

.cardHeaderTopBorder {
  border-top-left-radius: 16px !important;
  border-top-right-radius: 16px !important;
  cursor: pointer;
  display: flex;
  align-items: center;
}

.card-body {
  color: #424243;
  font-family: Roboto;
  font-size: 20px;
  font-style: normal;
  font-weight: 400;
  line-height: 32px;
  margin-left: 60px;
}

@media screen and (max-width: 992px) {
  .card-body {
    font-size: 1rem;
    line-height: 1.6rem;
    margin-left: 0;
  }
  
  .cardHeaderText{
    font-size: 1.4rem;
    line-height: 1.6rem;
  }

  .cardBodyText{
    font-size: 1rem;
    line-height: 1.6rem;
  }
}