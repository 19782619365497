.supportImagesWrapper {
  width: 100%;
  max-width: 100%;
  padding: 60px 3%;
  background-color: #F5FBFF;
  color: #424243;
}

/* Support Images */

.supportImagesContainer {
  max-width: 1500px;
}

.supportImagesRow {
  margin-top: 4rem;
  justify-content: center;
}

.supportImagesCol {
  margin-bottom: 20px;
}

@media screen and (max-width: 992px) {
  .supportImagesCol {
    width: 500px;
  }
}