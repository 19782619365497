.form-control {
  margin-bottom: 8px;
  border-width: 2px;
  border-color: #6D6D6D;
}

.form-label {
  color: #424243;
  font-family: Roboto;
  font-size: 16.328px;
  font-style: normal;
  font-weight: 400;
  line-height: 18.661px;
}

.form-check-label {
  font-family: Roboto;
}

.form-check-input {
  border-width: 2px;
  border-color: #6D6D6D;
}

.form-check-inline {
  margin-right: 1.5rem;
  margin-top: 0.5rem;
  margin-bottom: 0.5rem;
}

#formGridCheckbox {
  margin-top: 20px;
}

#formGridCheckbox>.form-check>.form-check-input {
  border-color: #6D6D6D;
}

#formGridCheckbox>.form-check>.form-check-label {
  color: #6D6D6D;
  line-height: 21px;
}

.spinner-border {
  margin: 11px 61px 2px 61px;
  min-width: none;
}