.footerContainerLogo {
  width: 100%;
  background-color: #fff;
  margin: 0;
  max-width: none;
}

.footerColumnLogo {
  padding: 32px
}

.footerImageLogo {
  max-width: 180px;
}

.footerContainerRefs {
  width: 100%;
  background-color: #D9D9D9;
  max-width: none;
  padding: 5%;
}

.footerStack {
  font-family: Roboto;
  font-size: 12px;
  font-weight: 400;
  line-height: 32px;
  text-align: left;
}

.footerStack > div > a {
  text-decoration: underline;
  color: inherit;
}